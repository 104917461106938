import React from 'react';
import { Link } from 'gatsby';

const PhoneNumberInfo = () => {
  return (
    <p>
      Więcej informacji pod nr. telefonu:{' '}
      <b>
        <a style={{ textDecoration: 'none', color: '#444444' }} href="tel:+48504396249">
          +48 504 396 249
        </a>
      </b>{' '}
      lub poprzez wysłanie <Link to="/kontakt">formularza</Link>.
    </p>
  );
};

export default PhoneNumberInfo;
