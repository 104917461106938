import React from 'react';

export default function Template({ data }) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { html } = markdownRemark;
  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </React.Fragment>
  );
}
